import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table as TableBase,
  TableCell,
  TableSortLabel,
  Switch,
} from "@mui/material";
import {
  TableData,
  TableDataItem,
  TableHeaderItem,
} from "types/components/table";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Text } from "../Text";
import { TableWrapper } from "components/common/TableWrapper";
import DeleteIcon from "../../../assets/images/DeleteIcon.svg";
import sorticonupgray from "../../../assets/images/sorticonupgray.svg";
import sortdownicon from "../../../assets/images/sortdownicon.svg";
import EditIcon from "../../../assets/images/EditIcon.svg";
import { useEffect, useState } from "react";
import { useGetListings, useRemoveListing } from "hooks";

import { notification } from "antd";
import { removeUser } from "services/UserService";
import { useLocation } from "react-router";

type OwnProps = {
  tableData: TableData;
  dataIdKey?: string;
  onRowClick?: (item: TableDataItem) => void;
  onDeleteClick?: (item: TableDataItem) => void;
  selectedRowId?: string;
  selectedRowIds?: string[];
  setCheck?: any;
  check?: any;
};

export const Table: React.FC<OwnProps> = (props) => {
  const location = useLocation();
  const {
    tableData,
    onRowClick,
    onDeleteClick,
    selectedRowId,
    selectedRowIds = [],
    dataIdKey = "id",
    setCheck,
    // check,
  } = props;

  const {
    loading: loadingRemoveListing,
    error: errorRemoveListing,
    removeListing,
  } = useRemoveListing();

  const [api, contextHolder] = notification.useNotification();
  const [sortKey, setSortKey] = useState<string>("");

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = (key: string, order: "asc" | "desc") => {
    // const isAsc = sortKey === key && sortOrder === "asc";
    // setSortOrder(isAsc ? "desc" : "asc");
    // setSortKey(key);

    setSortOrder(order);
    setSortKey(key);
  };

  const sortData = (data: any[], key: string, order: "asc" | "desc") => {
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return order === "asc" ? -1 : 1;
      if (a[key] > b[key]) return order === "asc" ? 1 : -1;
      return 0;
    });
  };

  const handleRemoveListing = (item: any) => {
    if (!item) {
      console.error("No selectedRowId found");
      return;
    }
    removeListing({ listingId: item.id }).then(() => {
      //getListings(); // Refresh the listings after removal
      // window.location.reload();
      setCheck(true);

      api.success({
        message: `Listing with ID ${item.id} removed successfully`,
        placement: "bottomRight",
      });
    });
  };

  const handleRemoveUser = (item: any) => {
    if (!item) {
      console.error("No selectedRowId found");
      return;
    }

    removeUser({ userId: item.id }).then((response) => {
      item.isDeleted = true;
      setCheck(true);

      api.success({
        message: `Listing with ID ${item.id} removed successfully`,
        placement: "bottomRight",
      });
    });

    setCheck(false);
  };

  const handleDelete = (item: any) => {
    if (location.pathname === "/manage-users") {
      handleRemoveUser(item);
    } else if (location.pathname === "/manage-listings") {
      handleRemoveListing(item);
    }
  };

  const handleToggleSwitch = (checked: boolean, item: any) => {
    if (checked) {
      handleRemoveUser(item); // Call the API when toggled on
    } else {
      console.log("Switch turned off but no action required.");
    }
  };

  // const generateTableHeaders = (tableData: TableData) =>
  //   tableData.headers.map((header) => (
  //     <TableCell
  //       key={header.key}
  //       style={{
  //         fontFamily: "PlusJakartaSans-Bold", // Set your font family for TableBody
  //       }}
  //     >
  //       {header.title}
  //     </TableCell>
  //   ));

  const generateTableHeaders = (
    tableData: TableData,
    sortKey: string,
    sortOrder: "asc" | "desc",
    handleSort: (key: string, order: "asc" | "desc") => void
  ) => (
    <>
      {tableData.headers.map((header) => (
        <TableCell
          key={header.key}
          style={{
            fontFamily: "PlusJakartaSans-Bold", // Set your font family for TableBody
            borderRight:
              header.key === "action" ? "1px solid #f4f4f4" : undefined,
            // backgroundColor: "red",
            width: "fit-content",
            whiteSpace: "nowrap",
            backgroundColor: header.key === "action" ? "#FFE500" : undefined,
            position: header.key === "action" ? "sticky" : undefined,
            top: header.key === "action" ? "0" : undefined,
            right: header.key === "action" ? "0" : undefined,
            left: header.key === "action" ? "0" : undefined,
            bottom: header.key === "action" ? "0" : undefined,
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
                width: "100%",
                paddingRight: "5px",
              }}
            >
              {/* <TableSortLabel
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  active={sortKey === header.key}
                  direction={sortKey === header.key ? sortOrder : "asc"}
                  onClick={() => handleSort(header.key)}
                  // IconComponent={() => (
                  // )}
                > */}
              {header.title}
              {/* </TableSortLabel> */}
              {header?.key !== "action" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    marginTop: "-4px",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                    }}
                  >
                    <img
                      src={sorticonupgray}
                      alt="sort-icon"
                      style={{
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",

                        cursor: "pointer",
                      }}
                      onClick={() => handleSort(header.key, "asc")}
                    />
                  </div>
                  <div style={{ width: "10px", height: "10px" }}>
                    <img
                      src={sortdownicon}
                      style={{
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",
                        marginBottom: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort(header.key, "desc")}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        </TableCell>
      ))}
      {/* Add a static "Action" header */}
      {/* <TableCell
        key="action"
        style={{
          fontFamily: "PlusJakartaSans-Bold",
          width: "fit-content",
          whiteSpace: "nowrap",
          backgroundColor: "green",
        }}
      ></TableCell> */}
    </>
  );

  const generateTableBody = (tableData: TableData) => {
    const sortedData = sortKey
      ? sortData(tableData.data, sortKey, sortOrder)
      : tableData.data;

    return (
      <TableBody>
        {sortedData.map((item, index) => {
          const rowKey = item[dataIdKey] || index.toString();
          const cells: JSX.Element[] = [];

          Object.keys(item).forEach((key) => {
            if (key.match(/^_/g) || key === dataIdKey) return;

            const value = item[key];
            let valueCell;

            if (key === "approved") {
              const awaitingApproval = item["awaiting_approval"];
              const status =
                value === true ? (
                  <>
                    <span
                      className="dot"
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "#ABD584",
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    ></span>
                    Approved
                  </>
                ) : value === false ? (
                  <>
                    <span
                      className="dot"
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "#FB88A1",
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    ></span>
                    Denied
                  </>
                ) : value === null && awaitingApproval === null ? (
                  <>
                    <span
                      className="dot"
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "#F4B557",
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    ></span>
                    Pending
                  </>
                ) : (
                  "-"
                );
              valueCell = (
                <TableCell
                  key={key}
                  style={{
                    fontFamily: "PlusJakartaSans-Regular",
                    color: "black",
                    borderRight: "2px solid #f4f4f4",
                    // backgroundColor: "red",
                    // padding: "0",
                    // margin: "0",
                    // height: "10px",
                    fontSize: "12px",
                    width: "fit-content",
                    whiteSpace: "nowrap",
                  }}
                >
                  {status}
                </TableCell>
              );
            } else if (typeof value === "boolean") {
              valueCell = (
                <TableCell
                  sx={{
                    textAlign: "center",
                    borderRight: "1px solid #f4f4f4",
                  }}
                  key={key}
                >
                  {value ? (
                    <CheckIcon
                      sx={{
                        color: "#ABD584",
                        height: "20px",
                      }}
                    />
                  ) : (
                    <DoNotDisturbIcon
                      sx={{
                        color: "#D5D5D5",
                        height: "20px",
                      }}
                    />
                  )}
                </TableCell>
              );
            } else if (key === "action") {
              valueCell = (
                <TableCell
                  key={key}
                  style={{
                    fontFamily: "PlusJakartaSans-Regular",
                    padding: "11.79px",
                    color: "black",
                    display: "flex",
                    gap: "8px",
                    // minHeight: "40px",
                    // backgroundColor: "yellow",

                    width: "100%",
                    whiteSpace: "nowrap",
                    position: "sticky",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  <img
                    src={EditIcon}
                    // style={{ height: "28px" }}
                    alt="edit"
                    onClick={(e) => onRowClick && onRowClick(item)}
                  />
                  {item["isDeleted"] !== undefined ? (
                    <Switch
                      checked={!item.isDeleted} // Ensure the value is explicitly a boolean
                      size="small"
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleToggleSwitch(!e.target.checked, item) // Extract the checked value
                      } // Correct handler // Use onChange for Switch
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#4caf50", // Customize the color when checked
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#4caf50", // Customize the track color when checked
                          },
                      }}
                    />
                  ) : (
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      onClick={() => handleDelete(item)}
                    />
                  )}
                </TableCell>
              );
            } else {
              valueCell = (
                <TableCell
                  key={key}
                  style={{
                    fontFamily: "PlusJakartaSans-Regular",
                    color: "black",
                    borderRight: "2px solid #f4f4f4",
                    width: "fit-content",
                    whiteSpace: "nowrap",
                    // backgroundColor: "green",
                    height: "10px",
                    fontSize: "12px",
                  }}
                >
                  {value || "-"}
                </TableCell>
              );
            }
            cells.push(valueCell);
          });

          // // Add action buttons
          // const actionCell = (
          //   <TableCell
          //     key={`${rowKey}-actions`}
          //     style={{
          //       fontFamily: "PlusJakartaSans-Regular",
          //       color: "black",
          //       display: "flex",
          //       gap: "8px",
          //     }}
          //   >
          //     <img src={EditIcon} style={{ height: "22px" }} alt="edit" />
          //     <img src={DeleteIcon} style={{ height: "22px" }} alt="delete" />
          //   </TableCell>
          // );

          // cells.push(actionCell);

          return (
            <TableRow
              hover={!!onRowClick}
              className={onRowClick ? "cursor-pointer" : ""}
              //onClick={(e) => onRowClick && onRowClick(item)}
              key={rowKey}
              selected={
                selectedRowId === item[dataIdKey] ||
                selectedRowIds.includes(item[dataIdKey] as string)
              }
            >
              {cells}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <>
      {!tableData?.data?.length ? (
        <Text
          style={{ fontSize: "12px", fontFamily: "PlusJakartaSans-Regular" }}
        >
          No data to be displayed
        </Text>
      ) : (
        <TableWrapper>
          <TableContainer
            component={Paper}
            className="margin-top-10"
            sx={{
              overflow: "auto",
              maxHeight: "70vh",
              position: "relative",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "6px",
                height: "6px",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#4caf50",
                borderRadius: "8px",
                border: "1px solid #f1f1f1",
              },
            }}
          >
            <TableBase size="small">
              <TableHead
                style={{
                  background: "#FFE500",
                  fontWeight: "700",
                  position: "sticky",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  zIndex: 3,
                  // width: "fit-conetent",
                }}
              >
                <TableRow sx={{ border: "10px" }}>
                  {generateTableHeaders(
                    tableData,
                    sortKey,
                    sortOrder,
                    handleSort
                  )}
                </TableRow>
              </TableHead>
              {generateTableBody(tableData)}
            </TableBase>
          </TableContainer>
        </TableWrapper>
      )}
    </>
  );
};
