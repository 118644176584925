import { HttpService } from "services";
import { User } from "types/entities";

import { GetUserFunction, GetUserFunctionParams } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Retrieve detailed information about listing
 *
 * @throws {HttpServiceException}
 */
export const getUser: GetUserFunction = async (
  params: GetUserFunctionParams
) => {
  try {
    const { userId } = params;
    let url = `/user/${userId}`;

    const res: User = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    // const arr = Object.entries(res);

    // return arr.map(([key, value]) =>
    //   HttpService.Serializer.serializeUser(value)
    // );

    return HttpService.Serializer.serializeSingleUser(res);

    // return res;

    // return {
    //   res?.given_name,
    //   res?.family_name,
    //   res?.telephone_number
    // }
  } catch (error) {
    throw error;
  }
};
