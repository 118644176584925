import { useGetMe, useHasRole } from "hooks";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  SignIn,
  ResetPassword,
  ManageUsers,
  ManageFiles,
  AppSettings,
  VendorZipcodes,
  ManageListings,
  ManageVirtualWalkthroughs,
  VendorEvents,
  ChangePassword,
  ManageTitles,
  Messages,
  ManageBulkGroups,
} from "views";
import {
  AUTH_ROUTER_PATHS,
  AUTH_ROUTER_ROLE_PATHS,
  UNATH_ROUTER_PATHS,
} from "types/navigation";
import { AuthenticatedView } from "components/wrappers";
import { GenerateDocuments } from "views/GenerateDocuments";
import { BulkAddListings } from "views/BulkAddListings";

const AUTHENTICATED_ROUTES = [
  {
    path: AUTH_ROUTER_PATHS.APP_SETTINGS,
    element: <AppSettings />,
  },
  {
    path: AUTH_ROUTER_PATHS.MANAGE_FILES,
    element: <ManageFiles />,
  },
  {
    path: AUTH_ROUTER_PATHS.MANAGE_LISTINGS,
    element: <ManageListings />,
  },
  {
    path: AUTH_ROUTER_PATHS.BULK_ADD_LISTINGS,
    element: <BulkAddListings />,
  },
  {
    path: AUTH_ROUTER_PATHS.MANAGE_BULK_GROUPS,
    element: <ManageBulkGroups />,
  },
  {
    path: AUTH_ROUTER_PATHS.MANAGE_USERS,
    element: <ManageUsers />,
  },
  {
    path: AUTH_ROUTER_PATHS.VENDOR_ZIPCODES,
    element: <VendorZipcodes />,
  },
  {
    path: AUTH_ROUTER_PATHS.VENDOR_EVENTS,
    element: <VendorEvents />,
  },
  {
    path: AUTH_ROUTER_PATHS.MANAGE_TITLES,
    element: <ManageTitles />,
  },
  {
    path: AUTH_ROUTER_PATHS.MANAGE_VIRTUAL_WALKTHROUGHS,
    element: <ManageVirtualWalkthroughs />,
  },
  {
    path: AUTH_ROUTER_PATHS.CHANGE_PASSWORD,
    element: <ChangePassword />,
  },
  {
    path: AUTH_ROUTER_PATHS.MESSAGES,
    element: <Messages />,
  },
  {
    path: AUTH_ROUTER_PATHS.GENERATE_DOCUMENTS,
    element: <GenerateDocuments />,
  },
  {
    path: "/*",
    element: <ManageFiles />,
  },
];

const UNAUTHENTICATED_ROUTES = [
  {
    path: UNATH_ROUTER_PATHS.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: UNATH_ROUTER_PATHS.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: UNATH_ROUTER_PATHS.CHANGE_PASSWORD,
    element: <ChangePassword />,
  },
  {
    path: "/*",
    element: <Navigate to={UNATH_ROUTER_PATHS.SIGN_IN} />,
  },
];

/**
 * Wraps logic for checking whether user is currently authenticated.
 * Renders routes accordingly.
 */
export const AuthenticationWrapper: React.FC = () => {
  const { loading, user } = useGetMe();

  const generateAuthRoutes = () => {
    if (!user) return <Routes />;

    const { isAdmin, isListingSpecialist, isVendor } = user;
    let availablePaths: string[] = [];

    if (isAdmin) availablePaths = [...AUTH_ROUTER_ROLE_PATHS.ADMINISTRATOR];
    if (isListingSpecialist)
      availablePaths = [
        ...availablePaths,
        ...AUTH_ROUTER_ROLE_PATHS.LISTING_SPECIALIST,
      ];
    if (isVendor)
      availablePaths = [...availablePaths, ...AUTH_ROUTER_ROLE_PATHS.VENDOR];

    // Get rid of duplicates
    availablePaths = [...new Set(availablePaths)];

    const roleFilteredRoutes = AUTHENTICATED_ROUTES.filter((route) =>
      availablePaths.includes(route.path)
    );

    return (
      <Routes>
        {roleFilteredRoutes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<AuthenticatedView>{element}</AuthenticatedView>}
          />
        ))}
      </Routes>
    );
  };

  const generateUnauthRoutes = () => (
    <Routes>
      {UNAUTHENTICATED_ROUTES.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  );

  // App initial loading state.
  if (loading) return null;

  if (user === undefined) return generateUnauthRoutes();

  return generateAuthRoutes();
};
