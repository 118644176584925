import {
  Paper,
  TableContainer,
  TableRow,
  TableBody,
  Table as TableBase,
  TableCell,
} from "@mui/material";
import { TableData } from "types/components/table";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Text } from "../Text";
import { TableWrapper } from "components/common";
import { BorderRight } from "@mui/icons-material";

type OwnProps = {
  tableData: TableData;
  showSplit: boolean; // New prop to control table splitting
};

export const ListTable: React.FC<OwnProps> = (props) => {
  const { tableData, showSplit } = props;

  const splitTableData = (tableData: TableData) => {
    const totalColumns = tableData.headers.length;
    if (totalColumns <= 10) {
      return [tableData]; // If less than or equal to 10 columns, no split needed
    }

    // Calculate the midpoint and split the headers and data into two parts
    const midpoint = Math.ceil(totalColumns / 2);
    // Split into two parts if there are more than 10 columns

    const firstPart = {
      headers: tableData.headers.slice(0, midpoint),
      data: tableData.data,
    };
    const secondPart = {
      headers: tableData.headers.slice(midpoint),
      data: tableData.data,
    };

    return [firstPart, secondPart];
  };

  const generateTableBody = (tableData: TableData) => {
    const tableRows: JSX.Element[] = [];

    const cellStyle = {
      width: "50%",
      // fontWeight: "600",
      borderRight: "3px solid #F6F6F6",
      fontFamily: "PlusJakartaSans-Regular",
      fontWeight: "800",
      fontSize: "12px",
    };
    const cellStyle1 = {
      width: "50%",
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: "12px",
      // display: "flex",
      // flexwrap: "nowrap"

      // fontWeight: "600"
    };

    tableData.headers.forEach((item) => {
      const key = item.key as string;
      const value = tableData.data[0][key];

      const labelCell = (
        <TableCell key="0" style={cellStyle}>
          {item.title}
        </TableCell>
      );
      let valueCell;
      if (typeof value === "boolean") {
        valueCell = (
          <TableCell key={key} style={cellStyle1}>
            {value ? (
              <CheckIcon sx={{ color: "#ABD584" }} />
            ) : (
              <DoNotDisturbIcon sx={{ color: "#D5D5D5" }} />
            )}
          </TableCell>
        );
      } else {
        valueCell = (
          <TableCell
            key={key}
            style={{
              ...cellStyle1,
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            {value || "-"}
          </TableCell>
        );
      }

      tableRows.push(
        <TableRow key={key}>
          {labelCell}
          {valueCell}
        </TableRow>
      );
    });

    return tableRows;
  };

  // const tableParts = splitTableData(tableData);
  const tableParts = showSplit ? splitTableData(tableData) : [tableData];

  return (
    <>
      {!tableData?.data?.length ||
      JSON.stringify(tableData?.data[0]) === JSON.stringify({}) ? (
        <Text>No data to be displayed</Text>
      ) : (
        //   <TableWrapper>
        //     <TableContainer component={Paper} className="margin-top-10">
        //       <TableBase size="small">
        //         <TableBody>{generateTableBody(tableData)}</TableBody>
        //       </TableBase>
        //     </TableContainer>
        //   </TableWrapper>

        // )}
        tableParts.map((part, index) => (
          <TableWrapper key={index}>
            <TableContainer component={Paper}>
              <TableBase size="small">
                <TableBody>{generateTableBody(part)}</TableBody>
              </TableBase>
            </TableContainer>
          </TableWrapper>
        ))
      )}
    </>
  );
};
