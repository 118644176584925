import { HttpService } from "services";

import { RemoveUserFunction, RemoveUserFunctionParams } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Remove a listing
 *
 * @throws {HttpServiceException}
 */
export const removeUser: RemoveUserFunction = async (
  params: RemoveUserFunctionParams
) => {
  try {
    const { userId } = params;
    let url = `/user/${userId}`;

    const res: boolean = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "DELETE",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
