import { HttpService } from "services";
import { ApiUser } from "types/api";
import { User } from "types/entities";
import { GetUsersFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve users of the app.
 *
 * @throws {UserServiceException}
 */
export const getUsers = async (
  ...args: Parameters<GetUsersFunction>
): Promise<User[]> => {
  try {
    let url = "/user";
    // const { keyword, ...otherParams } = args[0];

    if (args[0]) {
      const { keyword, filters } = args[0];

      url += generateQueryString({
        keyword: keyword?.toString(),
        filters: filters ? JSON.stringify(filters) : undefined,
      });
    }

    const users = await HttpService.fetch<ApiUser[]>(
      url,
      {
        requestConfig: {
          method: "GET",
          // // params: {
          // //   ...args[0],
          // // }
          // params: {
          //   keyword, // Add the `keyword` parameter here
          //   ...otherParams, // Spread other parameters
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return users.map((user) => HttpService.Serializer.serializeUser(user));
  } catch (error) {
    throw error;
  }
};
