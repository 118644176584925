import { HttpService } from "services";
import { Listing } from "types/entities";

import {
  GetListingsFunction,
  GetListingsFunctionParams,
} from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve listings
 *
 * @throws {HttpServiceException}
 */
export const getListings: GetListingsFunction = async (
  params?: GetListingsFunctionParams
) => {
  try {
    let url = "/listing";
    if (params) {
      const { keyword, awaitingApproval, filters, page } = params;
      url += generateQueryString({
        keyword: keyword?.toString(),
        awaitingApproval: awaitingApproval?.toString(),
        filters: filters ? JSON.stringify(filters) : undefined,
        page: page?.toString(),
      });
    }

    const res: Listing[] = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
