import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ViewWrapper } from "components/wrappers";
import "../../assets/styles/components/messages/styles.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ErrorMessage, LoadingIndicator, Text } from "components/common";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {
  useGetGeneratedDocumentPayload,
  useGetListings,
  useGetOffers,
  useSetFinalDocumentHash,
} from "hooks";
import { CSSProperties, useEffect, useState } from "react";
import { EversignDocumentType } from "types/entities";
import {
  useDocumentStore,
  useListingsStore,
  useOffersStore,
} from "store";
import { formatNumberToCurrencyString } from "utils";
import { List } from "components/common/List";
import { notification } from "antd";

type Option = {
  label: string;
  value: string;
};

const styles: { [key: string]: CSSProperties } = {
  row: {
    marginTop: 10,
  },
  paper: {
    marginTop: 10,
    padding: 16,
  },
};

export const GenerateDocuments = () => {
  const {
    loading: loadingListings,
    error: errorListings,
    getListings,
  } = useGetListings();
  const {
    loading: loadingOffers,
    error: errorOffers,
    getOffers,
  } = useGetOffers();
  const {
    loading: loadingHash,
    error: errorHash,
    setFinalDocumentHash,
  } = useSetFinalDocumentHash();
  const {
    loading: loadingPayload,
    getGeneratedDocumentPayload,
  } = useGetGeneratedDocumentPayload();
  const [mappedListingOptions, setMappedListingOptions] = useState<Option[]>(
    []
  );
  const [mappedOfferOptions, setMappedOfferOptions] = useState<Option[]>([]);
  const [awaitingApproval, setAwaitingApproval] = useState<boolean>(true);
  const { listings } = useListingsStore();
  const { offers } = useOffersStore();
  const { generatedDocumentPayload, clearGeneratedDocumentPayload } =
    useDocumentStore();
  const [selectedListingId, setSelectedListingId] = useState("");
  const [selectedOfferId, setSelectedOfferId] = useState("");
  const [selectedEversignDocumentType, setSelectedEversignDocumentType] =
    useState("");
  const [newDocumentLink, setNewDocumentLink] = useState("");
  const [newDocumentLinkIsInvalid, setNewDocumentLinkIsInvalid] =
    useState(false);
  const [api, contextHolder] = notification.useNotification();
  const eversignDocumentTypeOptions = [
    {
      value: EversignDocumentType.FINANCING,
      label: "Financing addendum",
    },
    {
      value: EversignDocumentType.OFFER,
      label: "Offer document",
    },
    {
      value: EversignDocumentType.DISCLOSURE,
      label: "Seller disclosure",
    },
    {
      value: EversignDocumentType.LISTING_AGREEMENT,
      label: "Listing agreement",
    },
  ];

  const mapListingsToOptions = () => {
    const newMappedListingOptions: Option[] = [];
    listings.forEach((listing) => {
      let address = "";
      address += listing.address_line_1 + ", ";
      if (listing.address_line_2) address += listing.address_line_2 + ", ";
      address += listing.city;

      newMappedListingOptions.push({
        label: address,
        value: listing.id,
      });
    });
    setMappedListingOptions([...newMappedListingOptions]);
  };

  const mapOffersToOptions = () => {
    const newMappedOfferOptions: Option[] = [];
    offers.forEach((offer) => {
      const offerAmount = formatNumberToCurrencyString(offer.offer_amount);
      const userFullName = `${offer.user.given_name} ${offer.user.family_name}`;
      const label = `${userFullName} - ${offerAmount}`;

      newMappedOfferOptions.push({
        label,
        value: offer.id,
      });
    });
    setMappedOfferOptions([...newMappedOfferOptions]);
  };

  const handleGenerateDocumentPayload = () => {
    if (
      (selectedEversignDocumentType ===
        EversignDocumentType.LISTING_AGREEMENT ||
        selectedEversignDocumentType === EversignDocumentType.DISCLOSURE) &&
      selectedListingId
    ) {
      getGeneratedDocumentPayload({
        listingId: selectedListingId,
        eversignDocumentType: selectedEversignDocumentType,
      });
    }
    if (
      (selectedEversignDocumentType === EversignDocumentType.OFFER ||
        selectedEversignDocumentType === EversignDocumentType.FINANCING) &&
      selectedListingId &&
      selectedOfferId
    ) {
      getGeneratedDocumentPayload({
        listingId: selectedListingId,
        offerId: selectedOfferId,
        eversignDocumentType: selectedEversignDocumentType,
      });
    }
  };

  const handleSubmitDocumentLink = () => {
    const splitLink = newDocumentLink.split("/");
    let documentHash;
    try {
      documentHash = splitLink[splitLink.length - 1];
      if (!documentHash || documentHash.length !== 32) {
        throw Error;
      }
    } catch {
      setNewDocumentLinkIsInvalid(true);
      return;
    }
    setFinalDocumentHash({
      listingId: selectedListingId,
      offerId: selectedOfferId,
      eversignDocumentType:
        selectedEversignDocumentType as EversignDocumentType,
      eversignDocumentHash: documentHash,
    });
    api.success({
      message: `Successfully set document`,
      placement: "bottomRight",
    });
    setNewDocumentLinkIsInvalid(false);
  };

  const generateLinkFromHash = (hash: string | undefined) => {
    if (hash) {
      return `https://realtybase.eversign.com/documents/${hash}`;
    }
    return "";
  };

  useEffect(() => {
    getListings({ awaitingApproval });
    setSelectedListingId("");
    setSelectedOfferId("");
  }, [awaitingApproval]);

  useEffect(() => {
    getListings({ awaitingApproval });
  }, []);

  useEffect(() => {
    clearGeneratedDocumentPayload();
  }, []);

  useEffect(() => {
    if (selectedListingId !== "") {
      getOffers({ listingId: selectedListingId });
      setSelectedOfferId("");
    }
  }, [selectedListingId]);

  useEffect(() => {
    mapListingsToOptions();
  }, [listings]);

  useEffect(() => {
    mapOffersToOptions();
  }, [offers]);

  useEffect(() => {
    setNewDocumentLink(generateLinkFromHash(generatedDocumentPayload?.hash));
  }, [generatedDocumentPayload]);

  useEffect(() => {
    handleGenerateDocumentPayload();
  }, [selectedListingId, selectedOfferId, selectedEversignDocumentType]);

  useEffect(() => {
    console.log(generatedDocumentPayload);
  }, [generatedDocumentPayload]);

  return (
    <ViewWrapper title="Generate documents">
      {contextHolder}
      <FormGroup>
        <div style={styles.row}>
          <TextField
            select
            label="Select document type"
            value={selectedEversignDocumentType}
            fullWidth
            onChange={(e) => setSelectedEversignDocumentType(e.target.value)}
          >
            {eversignDocumentTypeOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {selectedEversignDocumentType && (
          <>
            <div style={styles.row}>
              <FormControlLabel
                control={
                  <Switch
                    checked={awaitingApproval}
                    onChange={(e) => setAwaitingApproval(e.target.checked)}
                  />
                }
                label="Listings awaiting approval"
              />
            </div>

            <div style={styles.row}>
              {loadingListings ? (
                <LoadingIndicator indicatorColor="white" size={24} />
              ) : (
                <TextField
                  select
                  label="Select listing"
                  value={selectedListingId}
                  fullWidth
                  onChange={(e) => setSelectedListingId(e.target.value)}
                >
                  {mappedListingOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {errorListings && <ErrorMessage />}
            </div>

            {(selectedEversignDocumentType === EversignDocumentType.FINANCING ||
              selectedEversignDocumentType === EversignDocumentType.OFFER) && (
              <div style={styles.row}>
                {loadingOffers ? (
                  <LoadingIndicator indicatorColor="white" size={24} />
                ) : (
                  <TextField
                    select
                    label="Select offer"
                    value={selectedOfferId}
                    fullWidth
                    onChange={(e) => setSelectedOfferId(e.target.value)}
                  >
                    {mappedOfferOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {errorOffers && <ErrorMessage />}
              </div>
            )}

            {loadingPayload && (
              <LoadingIndicator style={styles.row} size={24} />
            )}

            {!loadingPayload && generatedDocumentPayload?.payload && (
              <>
                <div style={styles.row}>
                  <Text>
                    Please follow the link below and populate the document with
                    generated values
                  </Text>
                  <Link
                    href={generatedDocumentPayload?.link}
                    underline="hover"
                    target="_blank"
                  >
                    {generatedDocumentPayload?.link}
                  </Link>
                </div>
                <div style={styles.row}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Generated template fields</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List
                        items={Object.keys(
                          generatedDocumentPayload.payload
                        ).map((key) => {
                          let primaryText =
                            generatedDocumentPayload.payload[key];
                          if (primaryText === true) primaryText = "Checked";
                          if (primaryText === false) primaryText = "Unchecked";
                          return { primaryText, secondaryText: key };
                        })}
                        renderEmpty
                        noVerticalPadding
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div style={styles.row}>
                  <Text>
                    Once you've created a document - copy & paste the link to it
                    below.
                  </Text>
                </div>
                <div style={styles.row}>
                  <TextField
                    label="Document link"
                    value={newDocumentLink}
                    fullWidth
                    onChange={(e) => setNewDocumentLink(e.target.value)}
                  />
                  {newDocumentLinkIsInvalid && (
                    <ErrorMessage
                      descriptionText={`Invalid document link - expected the link to be in following format: \n"https://realtybase.eversign.com/documents/XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"`}
                    />
                  )}
                </div>
                <Button
                  variant="contained"
                  component="label"
                  disabled={!newDocumentLink}
                  style={{ marginTop: 10 }}
                  onClick={handleSubmitDocumentLink}
                >
                  {loadingHash ? (
                    <LoadingIndicator indicatorColor="white" size={24} />
                  ) : (
                    "Submit"
                  )}
                </Button>
                {errorHash && (
                  <ErrorMessage
                    descriptionText={
                      errorHash === true
                        ? `Failed to set new document hash`
                        : errorHash
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </FormGroup>
    </ViewWrapper>
  );
};
