import { useState } from "react";

import { useUserStore } from "store";
import { UpdateUserFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseUpdateUser extends BaseAsyncHook {
  updateUser: UpdateUserFunction;
}

export const useUpdateUser = (): UseUpdateUser => {
  const _updateUser = useUserStore((state) => state.updateUser);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const updateUser = async (...args: Parameters<UpdateUserFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const listing = await _updateUser(args[0]);
      return listing;
    } catch (error) {
      setIsError(true);
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    updateUser,
  };
};
