import { EntityId } from "types/common";

/**
 * User interface that should be used inside a global store.
 *
 * Depends heavily on the ApiUser type.
 */
export interface User {
  addressLine1: string | null;
  addressline2: string | null;
  city: string | null;
  dateOfBirth: Date | null;
  emailAddress: string;
  familyName: string | null;
  givenName: string | null;
  telephoneNumber: string | null;
  helpscoutSupportThreadId: string;
  id: EntityId;
  isAdmin: boolean;
  isCustomer: boolean;
  isListingSpecialist: boolean;
  isVendor: boolean;
  profilePicture: string | null;
  state: string | null;
  zip: string | null;
  zipcodes?: number[];
  sendbirdAccessToken: string;
  isDeleted: boolean;
}

export enum UserRole {
  ADMINISTRATOR = "ADMINISTRATOR",
  CUSTOMER = "CUSTOMER",
  LISTING_SPECIALIST = "LISTING_SPECIALIST",
  VENDOR = "VENDOR",
}

export enum VendorRole {
  INSPECTOR = "inspector",
  PHOTOGRAPHER = "photographer",
  TITLE_COMPANY = "title company",
}
