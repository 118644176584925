import { HttpService } from "services";
import { User } from "types/entities";

import { UpdateUserFunction, UpdateUserFunctionParams } from "types/functions";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * Retrieve detailed information about listing
 *
 * @throws {HttpServiceException}
 */
export const updateUser: UpdateUserFunction = async (
  params: UpdateUserFunctionParams
) => {
  try {
    const { userId, demographics } = params;

    const payload = {
      demographics: {
        givenName: demographics.givenName,
        familyName: demographics.familyName,
        telephoneNumber: demographics.telephoneNumber,
      },
    };

    const url = `/user/${userId}/create-demographics`;

    // Make the API request
    const res: User = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "PUT", // Adjusted to match the "create-demographics" endpoint semantics
          data: payload,
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
