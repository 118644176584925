import {
  List as MuiList,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { CSSProperties } from "react";

type TList = {
  items: { primaryText: string; secondaryText?: string }[];
  renderEmpty?: boolean;
  noVerticalPadding?: boolean;
};

const listItemStyle: CSSProperties = {
  paddingLeft: 0,
  paddingRight: 0,
};

export const List: React.FC<TList> = ({
  items,
  renderEmpty = true,
  noVerticalPadding = false,
}) => {
  let itemsToRender = items;
  if (!renderEmpty) {
    itemsToRender = itemsToRender.filter((item) => item.primaryText);
    console.log(1, itemsToRender);
  }
  return (
    <MuiList>
      {itemsToRender.map((item) => (
        <ListItem
          key={item.secondaryText}
          style={
            noVerticalPadding
              ? { ...listItemStyle, paddingTop: 0, paddingBottom: 0 }
              : listItemStyle
          }
        >
          <ListItemText
            primary={item.primaryText}
            secondary={item.secondaryText}
          />
        </ListItem>
      ))}
    </MuiList>
  );
};
