import { ApiUser } from "types/api";
import { User } from "types/entities";

export const serializeUser = (user: ApiUser): User => {
  return {
    addressLine1: user.address_line_1,
    addressline2: user.address_line_2,
    city: user.city,
    dateOfBirth: user.date_of_birth,
    emailAddress: user.email_address,
    familyName: user.family_name,
    telephoneNumber: user.telephone_number,
    givenName: user.given_name,
    helpscoutSupportThreadId: user.helpscout_support_thread_id,
    id: user.id,
    isAdmin: user.is_admin,
    isCustomer: user.is_customer,
    isListingSpecialist: user.is_listing_specialist,
    isVendor: user.is_vendor,
    profilePicture: user.profile_picture_blob,
    state: user.state,
    zip: user.zip,
    zipcodes: user.zipcodes,
    sendbirdAccessToken: user.sendbird_access_token,
    isDeleted: user.is_deleted,
  };
};

export const serializeSingleUser = (user: any): User => {
  return {
    addressLine1: user.address_line_1,
    addressline2: user.address_line_2,
    city: user.city,
    dateOfBirth: user.date_of_birth,
    emailAddress: user.email_address,
    familyName: user.family_name,
    telephoneNumber: user.telephone_number,
    givenName: user.given_name,
    helpscoutSupportThreadId: user.helpscout_support_thread_id,
    id: user.id,
    isAdmin: user.is_admin,
    isCustomer: user.is_customer,
    isListingSpecialist: user.is_listing_specialist,
    isVendor: user.is_vendor,
    profilePicture: user.profile_picture_blob,
    state: user.state,
    zip: user.zip,
    zipcodes: user.zipcodes,
    sendbirdAccessToken: user.sendbird_access_token,
    isDeleted: user.is_deleted,
  };
};
