import { useState } from "react";

import { useUserStore } from "store";
import { GetUserFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetUser extends BaseAsyncHook {
  getUser: GetUserFunction;
}

export const useGetUser = (): UseGetUser => {
  //   const _getUser = useListingsStore((state) => state.getListing);
  const _getUser = useUserStore((state) => state.getUser);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getUser = async (...args: Parameters<GetUserFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const user = await _getUser(args[0]);
      return user;
    } catch (error) {
      setIsError(true);
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getUser,
  };
};
