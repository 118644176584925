import React, { FormEvent, useState, useEffect, useRef } from "react";

import { useCreateUser, useGetUsers, useUpdateUser } from "hooks";
import { UserRole, VendorRole } from "types/entities";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TextField,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import {
  Button,
  CheckboxGroup,
  InputField,
  RadioGroup,
  Text,
} from "components/common";
import { ManageUsersTable } from "components/user";
import { MAX_GRID_ITEM_HEIGHT } from "consts/dimensions";
import { ViewWrapper } from "components/wrappers";
import { message, notification } from "antd";
import CrossDrawerIcon from "../../assets/images/CrossDrawerIcon.svg";
import createicon from "../../assets/images/createicon.svg";
import CreateUserIcon from "../../assets/images/CreateUserIcon.svg";
import { TableData, TableDataItem, TableDataItemValue } from "types/components";
import { UsersInStore } from "types/store";
import { SearchOutlined } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useUserStore } from "store";
import { useGetUser } from "hooks";
import { User } from "types/entities";
import { InputLabel } from "@mui/material";
import Modal from "@mui/material/Modal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import closeIcon from "../../assets/images/closeIcon.svg";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { INITIAL_USERS_TABLE_DATA } from "components/user/ManageUsersTable/consts";

interface Filter {
  column: string;
  operator: string;
  value: string;
}

export const ManageUsers: React.FC = () => {
  const { getUsers } = useGetUsers();
  const { getUser } = useGetUser();
  const { updateUser } = useUpdateUser();
  const {
    createUser,
    loading: createUserLoading,
    error: createUserError,
  } = useCreateUser();
  const { users } = useUserStore();
  const [usersTableData, setUsersTableData] = useState<TableData>({
    ...INITIAL_USERS_TABLE_DATA,
  });

  // console.log(usersTableData, "usersTableData");
  const [givenName, setGivenName] = useState<string>("");
  const [familyName, setFamilyName] = useState<string>("");
  const [telephoneNumber, setTelephoneNumber] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [userRoles, setUserRoles] = useState<Set<UserRole>>(
    new Set([UserRole.CUSTOMER])
  );
  const [vendorRole, setVendorRole] = useState<VendorRole>(
    VendorRole.INSPECTOR
  );
  const [api, contextHolder] = notification.useNotification();
  const [openUserDrawer, setOpenUserDrawer] = useState(false);

  const [keyword, setKeyword] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedListing, setSelectedListing] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const filterButtonRef = useRef(null);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });

  const mapUsersToTableData = (
    data: UsersInStore,
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data?.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof UsersInStore;
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const handleOpen = () => {
    // if (filterButtonRef.current) {
    //   const rect = filterButtonRef;
    //   // @ts-ignore
    //   const top = rect.current["offsetTop"] + 160;
    //   // @ts-ignore
    //   const left = rect.current["offsetLeft"] + 450;
    //   setPosition({
    //     top: top, // Adjust for vertical scrolling
    //     left: left, // Adjust for horizontal scrolling
    //   });
    // }
    setOpen(true);
  };
  const [filters, setFilters] = useState<Filter[]>([
    { column: "", operator: "", value: "" },
  ]);

  // console.log(filters, "filters");

  const toggleDrawer = (openUserDrawer: any) => () => {
    setOpenUserDrawer(openUserDrawer);
    setSelectedListing("");
    setGivenName("");
    setFamilyName("");
    setTelephoneNumber("");
  };

  useEffect(() => {
    mapUsersToTableData(users, INITIAL_USERS_TABLE_DATA, setUsersTableData);
  }, [users]);

  // console.log(usersTableData, "usersTableData");

  useEffect(() => {
    if (createUserError) {
      api.error({
        message: `Error while adding user`,
        placement: "bottomRight",
      });
    }
  }, [createUserError]);

  const handleAddUser = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email.length > 0 && givenName.length > 0 && familyName.length > 0) {
      const res = await createUser({
        email,
        familyName,
        givenName,
        roles: userRoles,
        vendorRole,
      });

      if (!res) return;
      api.success({
        message: `Successfully added user`,
        placement: "bottomRight",
      });

      setOpenUserDrawer(false);
      setRefresh(true);
      setFamilyName("");
      setGivenName("");
      setTelephoneNumber("");
      setEmail("");
    }
  };

  // Update a specific filter
  const handleUpdateFilter = (
    index: number,
    field: keyof Filter,
    value: string
  ) => {
    const updatedFilters = [...filters];
    updatedFilters[index][field] = value;
    setFilters(updatedFilters);
  };

  // Remove a specific filter row
  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const handleClose = () => setOpen(false);
  // Clear all filters
  const handleCleanAll = () => {
    setFilters([{ column: "", operator: "", value: "" }]);
  };

  const handleAddFilter = () => {
    setFilters([...filters, { column: "", operator: "", value: "" }]);
  };

  // const fetchUserDetails = async (selectedListing: string) => {
  //   const user = users?.find((user) => user.id === selectedListing);
  //   if (user) {
  //     const userDetail: {} = await getUser({ userId: selectedListing });
  //     const arr: User[] = [userDetail];

  //     console.log(userDetail.givenName, "arr");
  //   } else alert(`Can't find a listing with id: ${selectedListing}`);
  // };

  useEffect(() => {
    if (!open) {
      getUsers({ filters });
    }
  }, [open]);

  const fetchUserDetails = async (selectedListing: string) => {
    const user = useUserStore
      .getState()
      .users?.find((user) => user.id === selectedListing);

    if (user) {
      try {
        const userDetail = await useUserStore
          .getState()
          .getUser({ userId: selectedListing });

        if (userDetail && "givenName" in userDetail) {
          const arr: User[] = [userDetail];
          // console.log(userDetail.givenName, "arr");

          setGivenName(userDetail?.givenName || ""); // Provide fallback value
          setFamilyName(userDetail?.familyName || ""); // Provide fallback value
          setTelephoneNumber(userDetail?.telephoneNumber || "");
        } else {
          console.error("Invalid user details:", userDetail);
          alert("Received invalid user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    } else {
      alert(`Can't find a listing with id: ${selectedListing}`);
    }
  };

  const updateUserDetails = async () => {
    try {
      const userId = selectedListing; // Replace with actual user ID
      const demographics = {
        givenName: givenName,
        familyName: familyName,
        telephoneNumber: telephoneNumber,
      };

      const result = await updateUser({
        userId,
        demographics,
      });
      setRefresh(true);
      setOpenUserDrawer(false);
      setSelectedListing("");
      setGivenName("");
      setFamilyName("");
      setTelephoneNumber("");

      // console.log("Demographics updated successfully:", result);
    } catch (error) {
      console.error("Error updating demographics:", error);
    }
  };

  useEffect(() => {
    if (!selectedListing) return;
    fetchUserDetails(selectedListing);
  }, [selectedListing]);

  useEffect(() => {
    // if (keyword) {
    getUsers({ keyword });
    // }
  }, [keyword]);

  useEffect(() => {
    if (refresh) {
      getUsers({});
    }
    setRefresh(false);
  }, [refresh]);

  return (
    <ViewWrapper title="Manage users">
      {contextHolder}
      <Stack>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="20px"
        >
          <Box
            sx={{
              gap: "10px",
              display: "flex",
            }}
          >
            <TextField
              id="standard-bare"
              defaultValue="Search"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                ),
                style: {
                  borderRadius: "30px",
                  height: "35px",
                  width: "400px",
                  fontFamily: "PlusJakartaSans-Regular",
                },
              }}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <Button
              variant="outlined"
              style={{
                backgroundColor: "black",
                textTransform: "none",
                borderRadius: "30px",
                color: "white",
                border: "1px solid #DDDDDD",
              }}
              startIcon={<FilterListIcon />}
              onClick={handleOpen}
              ref={filterButtonRef}
            >
              Filter
            </Button>
          </Box>

          <Button
            variant="outlined"
            style={{
              backgroundColor: "black",
              textTransform: "none",
              borderRadius: "30px",
              color: "white",
              border: "1px solid #DDDDDD",
            }}
            startIcon={
              <img
                src={CreateUserIcon}
                alt="Create User Icon"
                style={{ height: 20 }}
              />
            }
            onClick={toggleDrawer(true)}
          >
            Create User
          </Button>
        </Box>
        <Text marginBottom="20px" marginTop="20px">
          {users?.length} Results
        </Text>
        <Grid container style={{ marginBottom: 20 }}>
          <Grid
            xs={12}
            item
            maxHeight={MAX_GRID_ITEM_HEIGHT}
            style={{ overflow: "auto" }}
          >
            <ManageUsersTable
              setOpenUserDrawer={setOpenUserDrawer}
              setSelectedListing={setSelectedListing}
            />
          </Grid>
        </Grid>
        <Drawer
          anchor="right"
          slotProps={{
            backdrop: {
              style: {
                backgroundColor: "#11111180 ",
                backdropFilter: "blur(2px)",
                // zIndex: "99999",
              },
            },
          }}
          open={openUserDrawer}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "16px",
              height: "97.5vh",
              margin: "10px",
              padding: "20px",

              width: "35%",
            },
          }}
        >
          <Box sx={{ width: "100%", padding: "25px", typography: "body1" }}>
            <Box
              marginBottom="20px"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
                // paddingLeft={"10px"}
              >
                {selectedListing ? "Update user" : "Create user"}
              </Text>
              <img
                src={CrossDrawerIcon}
                style={{ height: "30px", cursor: "pointer" }}
                onClick={toggleDrawer(false)}
              />
            </Box>

            <form onSubmit={handleAddUser}>
              <Stack spacing={2}>
                <InputField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      // Styling the border
                      "& fieldset": {
                        borderColor: "#E0E0E0",
                      },
                      // On hover
                      "&:hover fieldset": {
                        borderColor: "#D6D6D6",
                      },
                      // On focus
                      "&.Mui-focused fieldset": {
                        borderColor: "#CFCFCF",
                      },
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "PlusJakartaSans-Regular",
                      fontSize: "12px",
                      fontWeight: "600",
                    },
                  }}
                  style={{ marginBottom: "15px" }}
                  label={
                    <span
                      style={{
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      First Name*
                    </span>
                  }
                  type="text"
                  variant="outlined"
                  required
                  value={givenName}
                  onChange={setGivenName}
                />
                <InputField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      // Styling the border
                      "& fieldset": {
                        borderColor: "#E0E0E0",
                      },
                      // On hover
                      "&:hover fieldset": {
                        borderColor: "#D6D6D6",
                      },
                      // On focus
                      "&.Mui-focused fieldset": {
                        borderColor: "#CFCFCF",
                      },
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "PlusJakartaSans-Regular",
                      fontSize: "12px",
                      fontWeight: "600",
                    },
                  }}
                  style={{ marginBottom: "15px" }}
                  label={
                    <span
                      style={{
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Last Name*
                    </span>
                  }
                  type="text"
                  variant="outlined"
                  required
                  value={familyName}
                  onChange={setFamilyName}
                />

                {selectedListing ? (
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "15px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Telephone Number*
                      </span>
                    }
                    type="text"
                    variant="outlined"
                    required
                    value={telephoneNumber}
                    onChange={setTelephoneNumber}
                  />
                ) : (
                  <>
                    <InputField
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // Styling the border
                          "& fieldset": {
                            borderColor: "#E0E0E0",
                          },
                          // On hover
                          "&:hover fieldset": {
                            borderColor: "#D6D6D6",
                          },
                          // On focus
                          "&.Mui-focused fieldset": {
                            borderColor: "#CFCFCF",
                          },
                        },
                        "& .MuiInputBase-input": {
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "12px",
                          fontWeight: "600",
                        },
                      }}
                      style={{ marginBottom: "15px" }}
                      label={
                        <span
                          style={{
                            fontFamily: "PlusJakartaSans-Regular",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Email Address*
                        </span>
                      }
                      type="email"
                      variant="outlined"
                      required
                      value={email}
                      onChange={setEmail}
                    />
                    <CheckboxGroup<UserRole>
                      label="Choose user roles"
                      onChange={setUserRoles}
                      options={[
                        {
                          label: "Listing specialist",
                          value: UserRole.LISTING_SPECIALIST,
                        },
                        {
                          label: "Administrator",
                          value: UserRole.ADMINISTRATOR,
                        },
                        {
                          label: "Vendor",
                          value: UserRole.VENDOR,
                        },
                      ]}
                      value={userRoles}
                    />
                    {userRoles.has(UserRole.VENDOR) ? (
                      <RadioGroup<VendorRole>
                        onChange={setVendorRole}
                        value={vendorRole}
                        label="Vendor role"
                        name="vendor-role"
                        options={[
                          {
                            label: "Inspector",
                            value: VendorRole.INSPECTOR,
                          },
                          {
                            label: "Photographer",
                            value: VendorRole.PHOTOGRAPHER,
                          },
                          {
                            label: "Title company",
                            value: VendorRole.TITLE_COMPANY,
                          },
                        ]}
                      />
                    ) : null}
                  </>
                )}

                {selectedListing ? (
                  <Button
                    style={{
                      backgroundColor: "#FFE500",
                      borderRadius: "40px",
                      color: "black",
                      height: "35px",
                      width: "fit-content",
                      padding: "0px 15px 0px 15px",
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                      textTransform: "none",
                    }}
                    // type="submit"
                    // disabled={createUserLoading}
                    onClick={updateUserDetails}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#FFE500",
                      borderRadius: "40px",
                      color: "black",
                      height: "35px",
                      width: "fit-content",
                      padding: "0px 15px 0px 15px",
                      fontSize: "12px",
                      display: "flex",
                      gap: "5px",
                      whiteSpace: "nowrap",
                      textTransform: "none",
                    }}
                    type="submit"
                    disabled={createUserLoading}
                  >
                    <img src={createicon} />
                    <span> Create User</span>
                  </Button>
                )}
              </Stack>
            </form>
          </Box>
        </Drawer>

        <Modal
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            style: {
              backgroundColor: "#11111180 ",
              backdropFilter: "blur(2px)",
            }, // Removes opacity
          }}
        >
          <Box
            sx={{
              // position: "absolute",
              // top: `0`,
              // left: `0`,
              // right: `0`,
              // bottom: `0`,
              // transform: "translate(-50%, -50%)",
              width: 900,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 3,
              p: 2,
            }}
          >
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              marginTop="20px"
            >
              <p
                style={{
                  margin: 0,
                  paddingBottom: "30px",
                  fontFamily: "PlusJakartaSans-SemiBold",
                }}
              >
                Filter
              </p>
            </Box>
            <Box sx={{ justifyContent: "center" }}>
              <Box sx={{ maxHeight: "350px", overflowX: "auto" }}>
                {filters.map((filter, index) => (
                  <>
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 2,
                        gap: "10px",
                        mt: 4,
                      }}
                    >
                      {/* <FormControl>
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Column
                      </InputLabel>
                      <Box sx={{ marginBottom: "15px" }} />
                      <TextField
                        id={`column-${index}`}
                        value={filter.column}
                        onChange={(e) =>
                          handleUpdateFilter(index, "column", e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            </IconButton>
                          ),
                          style: {
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          },
                        }}
                        sx={{ marginLeft: "10px", marginRight: "10px" }}
                      />
                    </FormControl> */}
                      {filters?.length > 1 && (
                        <img
                          src={closeIcon}
                          alt="Close Icon"
                          style={{ cursor: "pointer", marginTop: "3px" }}
                          onClick={() => handleRemoveFilter(index)}
                        />
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "30%",
                        }}
                      >
                        <InputLabel
                          htmlFor="first-name"
                          sx={{
                            color: "black",
                            fontFamily: "PlusJakartaSans-SemiBold",
                            marginTop: "-28px",
                          }}
                        >
                          Column
                        </InputLabel>
                        <FormControl>
                          <Select
                            id={`column-${index}`}
                            value={filter.column}
                            onChange={(e) =>
                              handleUpdateFilter(
                                index,
                                "column",
                                e.target.value
                              )
                            }
                            sx={{
                              borderRadius: "30px",
                              height: "35px",
                              width: "100%",
                              fontSize: "15px",
                              fontFamily: "PlusJakartaSans-Regular",
                            }}
                            IconComponent={() => (
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            )}
                          >
                            {/* {usersTableData?.headers?.map((h) => (
                              <MenuItem key={h.key} value={h.key}>
                                {h.title}
                              </MenuItem>
                            ))} */}

                            <MenuItem value="given_name">Given Name</MenuItem>
                            <MenuItem value="family_name">Family Name</MenuItem>
                            <MenuItem value="email_address">Email</MenuItem>
                            <MenuItem value="address_line_1">
                              Address Line 1
                            </MenuItem>
                            <MenuItem value="address_line_2">
                              Address Line 2
                            </MenuItem>
                            <MenuItem value="city">City</MenuItem>
                            <MenuItem value="state">State</MenuItem>
                            <MenuItem value="zip">Zip</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "30%",
                        }}
                      >
                        <InputLabel
                          id={`operator-label-${index}`}
                          sx={{
                            color: "black",
                            fontFamily: "PlusJakartaSans-SemiBold",
                            marginTop: "-28px",
                          }}
                        >
                          Operator
                        </InputLabel>
                        <FormControl>
                          <Select
                            labelId={`operator-label-${index}`}
                            id={`operator-${index}`}
                            value={filter.operator}
                            onChange={(e) =>
                              handleUpdateFilter(
                                index,
                                "operator",
                                e.target.value
                              )
                            }
                            sx={{
                              borderRadius: "30px",
                              height: "35px",
                              width: "100%",
                              fontSize: "15px",
                              fontFamily: "PlusJakartaSans-Regular",
                            }}
                            IconComponent={() => (
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            )}
                          >
                            <MenuItem value="equals">=</MenuItem>
                            <MenuItem value="not equals">!=</MenuItem>
                            <MenuItem value="contain">Contain</MenuItem>
                            <MenuItem value="not contain">
                              {" "}
                              Not Contain
                            </MenuItem>
                            <MenuItem value="start with">Starts With</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>

                      {/* <FormControl>
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Operator
                      </InputLabel>
                      <Box sx={{ marginBottom: "15px" }} />
                      <TextField
                        id={`operator-${index}`}
                        value={filter.operator}
                        onChange={(e) =>
                          handleUpdateFilter(index, "operator", e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            </IconButton>
                          ),
                          style: {
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          },
                        }}
                        sx={{ marginLeft: "10px", marginRight: "10px" }}
                      />{" "}
                    </FormControl> */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "30%",
                        }}
                      >
                        <InputLabel
                          htmlFor="first-name"
                          sx={{
                            color: "black",
                            fontFamily: "PlusJakartaSans-SemiBold",
                            marginTop: "-28px",
                          }}
                        >
                          Value
                        </InputLabel>
                        <FormControl>
                          <TextField
                            id={`value-${index}`}
                            value={filter.value}
                            onChange={(e) =>
                              handleUpdateFilter(index, "value", e.target.value)
                            }
                            InputProps={{
                              style: {
                                borderRadius: "30px",
                                height: "35px",
                                fontSize: "15px",
                                fontFamily: "PlusJakartaSans-Regular",
                              },
                            }}
                            sx={{ marginRight: "10px" }}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </>
                ))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginTop="20px"
              >
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "#FFE500",
                    textTransform: "none",
                    borderRadius: "30px",
                    color: "black",
                    border: "1px solid #DDDDDD",
                    fontSize: "15px",
                    fontFamily: "PlusJakartaSans-Regular",
                  }}
                  startIcon={<ControlPointIcon />}
                  onClick={handleAddFilter}
                >
                  Add Filter
                </Button>

                <p
                  style={{
                    cursor: "pointer",
                    margin: 0,
                    textDecoration: "underline",
                    fontSize: "15px",
                    color: "black",
                    fontFamily: "PlusJakartaSans-Regular",
                  }}
                  onClick={handleCleanAll}
                >
                  Clean All
                </p>
              </Box>
            </Box>
          </Box>
        </Modal>
        {/* <Text variant="h4" fontWeight={600} marginBottom="20px">
          Create user
        </Text>

        <form onSubmit={handleAddUser}>
          <Stack spacing={2}>
            <InputField
              label="Given name"
              type="text"
              variant="outlined"
              required
              value={givenName}
              onChange={setGivenName}
            />
            <InputField
              label="Family name"
              type="text"
              variant="outlined"
              required
              value={familyName}
              onChange={setFamilyName}
            />
            <InputField
              label="Email"
              type="email"
              variant="outlined"
              required
              value={email}
              onChange={setEmail}
            />
            <CheckboxGroup<UserRole>
              label="Choose user roles"
              onChange={setUserRoles}
              options={[
                {
                  label: "Listing specialist",
                  value: UserRole.LISTING_SPECIALIST,
                },
                {
                  label: "Administrator",
                  value: UserRole.ADMINISTRATOR,
                },
                {
                  label: "Vendor",
                  value: UserRole.VENDOR,
                },
              ]}
              value={userRoles}
            />
            {userRoles.has(UserRole.VENDOR) ? (
              <RadioGroup<VendorRole>
                onChange={setVendorRole}
                value={vendorRole}
                label="Vendor role"
                name="vendor-role"
                options={[
                  {
                    label: "Inspector",
                    value: VendorRole.INSPECTOR,
                  },
                  {
                    label: "Photographer",
                    value: VendorRole.PHOTOGRAPHER,
                  },
                  {
                    label: "Title company",
                    value: VendorRole.TITLE_COMPANY,
                  },
                ]}
              />
            ) : null}
            <Button
              variant="contained"
              type="submit"
              disabled={createUserLoading}
            >
              Create
            </Button>
          </Stack>
        </form> */}
      </Stack>
    </ViewWrapper>
  );
};
