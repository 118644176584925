/**
 * Format provided number to US currency string
 * ex. 2500 -> $2,500.00
 */
export function formatNumberToCurrencyString(val: number) {
  if (typeof val !== "number") {
    return "NaN";
  }
  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(val);
}
