import {
  Grid,
  GridProps,
  TextField,
  IconButton,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Drawer,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Text, ListTable, Table } from "components/common";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  useGetListings,
  useGetListing,
  useGetListingSeller,
  useGetEvents,
  useGetOffers,
  useRemoveListing,
} from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { useListingsStore } from "store";
import { TableData, TableDataItem, TableDataItemValue } from "types/components";
import {
  DetailedListing,
  Listing,
  ListingMoreProperties,
  Event,
  Offer,
  ListingSeller,
} from "types/entities";
import {
  INITIAL_LISTINGS_TABLE_DATA,
  INITIAL_LISTING_GENERIC_TABLE_DATA,
  INITIAL_LISTING_DETAILS_TABLE_DATA,
  INITIAL_LISTING_EVENTS_TABLE_DATA,
  INITIAL_LISTING_OFFERS_TABLE_DATA,
} from "./";
import { useEventsStore, useOffersStore } from "store";
import { getFormattedDate } from "utils";
import {
  ManageListingsUpdateForm,
  ManageListingsPhotoSelector,
  ManageListingsInspectionDocs,
} from "components/listing";
import {} from "components/listing";
import { DIMENSIONS } from "consts";
import { ViewWrapper } from "components/wrappers";
import { notification } from "antd";
import { RemoveListingFunctionParams } from "types/functions";
import { ControlPointRounded, SearchOutlined } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SplitscreenIcon from "../../assets/images/Frame.svg";
import closeIcon from "../../assets/images/closeIcon.svg";

import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { filter } from "lodash";

interface Filter {
  column: string;
  operator: string;
  value: string;
}

export const ManageListings: React.FC = () => {
  const { getListings } = useGetListings();
  const { getListing } = useGetListing();
  const { getListingSeller } = useGetListingSeller();
  const {
    loading: loadingRemoveListing,
    error: errorRemoveListing,
    removeListing,
  } = useRemoveListing();
  const { getEvents } = useGetEvents({
    configuration: { preventNetworkRequest: true },
  });
  const { getOffers } = useGetOffers();
  const { listings, listing, listingSeller } = useListingsStore();
  const { events } = useEventsStore();
  const { offers } = useOffersStore();
  const [listingsTableData, setListingsTableData] = useState<TableData>({
    ...INITIAL_LISTINGS_TABLE_DATA,
  });
  const [listingDetailsTableData, setListingDetailsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_DETAILS_TABLE_DATA });
  const [listingFeaturesTableData, setListingFeaturesTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingConditionsTableData, setListingConditionsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingFloodInfoTableData, setListingFloodInfoTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingMoreInfoTableData, setListingMoreInfoTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingDefectsTableData, setListingDefectsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingExclusionsTableData, setListingExclusionsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingSellerTableData, setListingSellerTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingEventsTableData, setListingEventsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_EVENTS_TABLE_DATA });
  const [listingOffersTableData, setListingOffersTableData] =
    useState<TableData>({ ...INITIAL_LISTING_OFFERS_TABLE_DATA });
  const [api, contextHolder] = notification.useNotification();

  const [selectedListing, setSelectedListing] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");

  const [check, setCheck] = useState<boolean>(false);
  const [apiCalled, setApiCalled] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleRowClick = (
    item: TableDataItem,
    setStateFunc: React.Dispatch<React.SetStateAction<string>>,
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setStateFunc(item.id as string);
    setOpenDrawer(true); // Set openDrawer to true
  };
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const filterButtonRef = useRef(null);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });
  const handleOpen = () => {
    if (filterButtonRef.current) {
      const rect = filterButtonRef;
      // @ts-ignore
      const top = rect.current["offsetTop"] + 160;
      // @ts-ignore
      const left = rect.current["offsetLeft"] + 450;
      setPosition({
        top: top, // Adjust for vertical scrolling
        left: left, // Adjust for horizontal scrolling
      });
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  //Filters
  const [filters, setFilters] = useState<Filter[]>([
    { column: "", operator: "", value: "" },
  ]);
  const handleAddFilter = () => {
    setFilters([...filters, { column: "", operator: "", value: "" }]);
  };

  // console.log(filters, "filters");

  // Remove a specific filter row
  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  // Clear all filters
  const handleCleanAll = () => {
    setFilters([{ column: "", operator: "", value: "" }]);
  };

  // Update a specific filter
  const handleUpdateFilter = (
    index: number,
    field: keyof Filter,
    value: string
  ) => {
    const updatedFilters = [...filters];
    updatedFilters[index][field] = value;
    setFilters(updatedFilters);
  };

  //Drawer

  const toggleDrawer = (newOpen: any) => () => {
    setOpenDrawer(newOpen);
  };

  //Tabs
  const [value, setValue] = useState("Information");

  const handleChangeTabs = (event: any, newValue: any) => {
    setValue(newValue);
  };

  /**
   * Maps data while adhering their key->title mapping set in their INITIAL_*_TABLE_DATA const var
   */
  const mapTranslatedDataToTableData = (
    data: Listing[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapEventsToTableData = (
    data: Event[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };
    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Event;

        // Handle cases requiring data translation
        if (key === "inviter" || key === "invitee") {
          if (Object.keys(item[key]).length === 0) {
            tableItem[key] = "-";
          } else {
            tableItem[key] = `${item[key].given_name} ${item[key].family_name}`;
          }
          return;
        }
        if (key === "minutes_start" || key === "minutes_end") {
          tableItem[key] =
            Math.floor(item[key] / 60)
              .toString()
              .padStart(2, "0") +
            ":" +
            Math.floor(item[key] % 60)
              .toString()
              .padStart(2, "0") +
            " UTC";
          return;
        }
        if (key === "date_specific") {
          const date = new Date(item[key]);
          tableItem[key] = getFormattedDate(date);
          return;
        }
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapOffersToTableData = (
    data: Offer[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };
    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Offer;

        // Handle cases requiring data translation
        if (key === "user") {
          tableItem[key] = `${item[key].given_name} ${item[key].family_name}`;
          return;
        }
        if (key === "closing_date" || key === "last_update_date") {
          const date = new Date(item[key]);
          tableItem[key] = getFormattedDate(date);
          return;
        }

        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapListingDetailsToTableData = (listing: DetailedListing) => {
    const newTableData: TableData = {
      ...INITIAL_LISTING_DETAILS_TABLE_DATA,
      data: [],
    };
    const data: TableDataItem = {};

    newTableData.headers.forEach((header) => {
      const key = header.key as keyof DetailedListing;
      data[key] = listing[key] as TableDataItemValue;
    });
    newTableData.data = [data];

    setListingDetailsTableData({ ...newTableData });
  };

  /**
   * Maps data while disregarding their key->title mapping set in their INITIAL_*_TABLE_DATA const var
   */
  const mapNonTranslatedDetailsToListTableData = (
    data: ListingMoreProperties[keyof ListingMoreProperties] | ListingSeller,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const keys = Object.keys(data);
    const newTableData: TableData = {
      headers: keys.map((key) => ({ key, title: key })),
      data: [],
    };
    newTableData.data = [data as TableDataItem];

    setStateFunc({ ...newTableData });
  };

  const fetchListingDetails = (selectedListing: string) => {
    const listing = listings.find((listing) => listing.id === selectedListing);
    if (listing) {
      getListing({ listingId: selectedListing });
      getOffers({ listingId: selectedListing });
    } else alert(`Can't find a listing with id: ${selectedListing}`);
  };

  useEffect(() => {
    mapTranslatedDataToTableData(
      listings,
      INITIAL_LISTINGS_TABLE_DATA,
      setListingsTableData
    );
  }, [listings]);

  useEffect(() => {
    if (!selectedListing) return;
    fetchListingDetails(selectedListing);
  }, [selectedListing]);

  useEffect(() => {
    // Check if listing is empty
    if (!listing || JSON.stringify(listing) === JSON.stringify({})) return;

    // Typescript doesn't seem to care that we've just checked whether listing object
    // is empty. Unfortunately we need to spam type assertion.
    mapListingDetailsToTableData(listing as DetailedListing);
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.features,
      setListingFeaturesTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.conditions,
      setListingConditionsTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.defects,
      setListingDefectsTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.flood_info,
      setListingFloodInfoTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.more_info,
      setListingMoreInfoTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.exclusions,
      setListingExclusionsTableData
    );
    getListingSeller({ listing: listing as DetailedListing });
    getEvents({ listingId: (listing as DetailedListing).id });
  }, [listing]);

  const appendNewListingsToTableData = (
    newListings: Listing[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const updatedTableData: TableData = { ...initialTableData };

    newListings.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Ensure every tableItem contains 'id' field
      tableItem.id = item.id;

      updatedTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      updatedTableData.data.push(tableItem);
    });

    setStateFunc({ ...updatedTableData });
  };

  useEffect(() => {
    // Check if listingSeller is empty
    if (!listingSeller || JSON.stringify(listingSeller) === JSON.stringify({}))
      return;

    mapNonTranslatedDetailsToListTableData(
      listingSeller as ListingSeller,
      setListingSellerTableData
    );
  }, [listingSeller]);

  useEffect(() => {
    mapEventsToTableData(
      events,
      INITIAL_LISTING_EVENTS_TABLE_DATA,
      setListingEventsTableData
    );
  }, [events]);

  useEffect(() => {
    mapOffersToTableData(
      offers,
      INITIAL_LISTING_OFFERS_TABLE_DATA,
      setListingOffersTableData
    );
  }, [offers]);

  useEffect(() => {
    // console.log(filters, "====");
    const fetchListings = async () => {
      setIsLoading(true);
      try {
        if (filters[0]?.column?.trim() == "") {
          const newListings = await getListings({ page, keyword });
          if (!apiCalled) {
            appendNewListingsToTableData(
              newListings,
              listingsTableData,
              setListingsTableData
            );
          } else {
            getListings({ page, keyword });
            setApiCalled(false);
          }
        } else {
          const newListings = await getListings({ filters, page, keyword });
          if (page != 1) {
            appendNewListingsToTableData(
              newListings,
              listingsTableData,
              setListingsTableData
            );
          }
        }
      } catch (error) {
        console.error("Error fetching listings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchListings();
  }, [page]);

  useEffect(() => {
    setApiCalled(true);
    const delayDebounce = setTimeout(() => {
      if (filters[0]?.column?.trim() == "") {
        if (!keyword || keyword?.trim() === "") {
          if (page == 1) {
            getListings({ keyword, page: 1 });
          } else {
            setPage(1);
          }
        } else {
          if (page == 1) {
            getListings({ keyword, page: 1 });
          } else {
            setPage(1);
          }

          // console.log("API called with keyword:", keyword);
        }
      } else {
        if (!keyword || keyword?.trim() === "") {
          if (page == 1) {
            setPage(1);
            getListings({ filters, keyword, page: 1 });
          } else {
            setPage(1);
          }
        } else {
          if (page == 1) {
            setPage(1);
            getListings({ filters, keyword, page: 1 });
          } else {
            setPage(1);
          }

          // console.log("API called with keyword:", keyword);
        }
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [keyword, 500]);

  // useEffect(() => {
  //   const delayDebounce = setTimeout(() => {
  //     getListings({ keyword });
  //     // console.log("API called with keyword:", keyword);
  //   }, 500);

  //   return () => clearTimeout(delayDebounce);
  // }, [keyword]);

  useEffect(() => {
    if (check) {
      getListings();
      setCheck(false);
    }
  }, [check]);

  useEffect(() => {
    if (!open) {
      setPage(1);
      getListings({ filters });
      setKeyword("");
    }
  }, [open]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
    setApiCalled(false);
  };

  useEffect(() => {
    if (!loadingRemoveListing && !errorRemoveListing) {
      setSelectedListing("");
    }
  }, [loadingRemoveListing, errorRemoveListing]);
  interface GridItemProps extends GridProps {
    children: React.ReactNode;
  }
  const GridItem: React.FC<GridItemProps> = ({ children, ...props }) => (
    <Grid
      item
      xs={3}
      maxHeight={DIMENSIONS.MAX_GRID_ITEM_HEIGHT}
      overflow="auto"
      {...props}
    >
      {children}
    </Grid>
  );

  const style = {
    position: "absolute",
    top: "31.5%",
    left: "49.5%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
  };

  //   // if (!selectedRowId) {
  //   //   console.error("No selectedRowId found");
  //   //   return;
  //   // }
  //   // console.log(selectedRowId, "selectedRowIdss");
  //   // removeListing({ listingId: selectedRowId }).then(() => {
  //   //   getListings(); // Refresh the listings after removal
  //   //   api.success({
  //   //     message: `Listing with ID ${selectedRowId} removed successfully`,
  //   //     placement: "bottomRight",
  //   //   });
  //   // });
  // };
  return (
    <ViewWrapper title="Manage Listings">
      {contextHolder}

      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <TextField
          id="standard-bare"
          // style={{borderRadius:"30px"}}
          defaultValue="Search"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
            style: {
              borderRadius: "30px",
              height: "40px",
              width: "400px",
              fontFamily: "PlusJakartaSans-Regular",
            },
          }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />

        <Button
          variant="outlined"
          style={{
            backgroundColor: "black",
            textTransform: "none",
            borderRadius: "30px",
            color: "white",
            border: "1px solid #DDDDDD",
            fontFamily: "PlusJakartaSans-Regular",
          }}
          startIcon={<FilterListIcon />}
          onClick={handleOpen}
          ref={filterButtonRef}
        >
          Filter
        </Button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: "transparent",
            backdropFilter: "blur(2px)",
          }, // Removes opacity
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: `${position?.top}px`,
            left: `${position?.left}px`,
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 3,
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            marginTop="20px"
          >
            <p
              style={{
                margin: 0,
                paddingBottom: "30px",
                fontFamily: "PlusJakartaSans-SemiBold",
              }}
            >
              Filter
            </p>
          </Box>
          <Box sx={{ justifyContent: "center" }}>
            <Box sx={{ maxHeight: "350px", overflowX: "auto" }}>
              {filters.map((filter, index) => (
                <>
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      gap: "10px",
                      mt: 4,
                    }}
                  >
                    {/* <FormControl>
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Column
                      </InputLabel>
                      <Box sx={{ marginBottom: "15px" }} />
                      <TextField
                        id={`column-${index}`}
                        value={filter.column}
                        onChange={(e) =>
                          handleUpdateFilter(index, "column", e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            </IconButton>
                          ),
                          style: {
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          },
                        }}
                        sx={{ marginLeft: "10px", marginRight: "10px" }}
                      />
                    </FormControl> */}
                    {filters?.length > 1 && (
                      <img
                        src={closeIcon}
                        alt="Close Icon"
                        style={{ cursor: "pointer", marginTop: "3px" }}
                        onClick={() => handleRemoveFilter(index)}
                      />
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "30%",
                      }}
                    >
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Column
                      </InputLabel>
                      <FormControl>
                        <Select
                          id={`column-${index}`}
                          value={filter.column}
                          onChange={(e) =>
                            handleUpdateFilter(index, "column", e.target.value)
                          }
                          sx={{
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          }}
                          IconComponent={() => (
                            <ArrowDropDownIcon sx={{ color: "black" }} />
                          )}
                        >
                          {listingsTableData?.headers
                            ?.filter((x) => x.title !== "Status")
                            ?.map((h) => (
                              <MenuItem key={h.key} value={h.key}>
                                {h.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "30%",
                      }}
                    >
                      <InputLabel
                        id={`operator-label-${index}`}
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Operator
                      </InputLabel>
                      <FormControl>
                        <Select
                          labelId={`operator-label-${index}`}
                          id={`operator-${index}`}
                          value={filter.operator}
                          onChange={(e) =>
                            handleUpdateFilter(
                              index,
                              "operator",
                              e.target.value
                            )
                          }
                          sx={{
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          }}
                          IconComponent={() => (
                            <ArrowDropDownIcon sx={{ color: "black" }} />
                          )}
                        >
                          <MenuItem value="equals">=</MenuItem>
                          <MenuItem value="not equals">!=</MenuItem>
                          <MenuItem value="contain">Contain</MenuItem>
                          <MenuItem value="not contain"> Not Contain</MenuItem>
                          <MenuItem value="start with">Starts With</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    {/* <FormControl>
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Operator
                      </InputLabel>
                      <Box sx={{ marginBottom: "15px" }} />
                      <TextField
                        id={`operator-${index}`}
                        value={filter.operator}
                        onChange={(e) =>
                          handleUpdateFilter(index, "operator", e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            </IconButton>
                          ),
                          style: {
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          },
                        }}
                        sx={{ marginLeft: "10px", marginRight: "10px" }}
                      />{" "}
                    </FormControl> */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "30%",
                      }}
                    >
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Value
                      </InputLabel>
                      <FormControl>
                        <TextField
                          id={`value-${index}`}
                          value={filter.value}
                          onChange={(e) =>
                            handleUpdateFilter(index, "value", e.target.value)
                          }
                          InputProps={{
                            style: {
                              borderRadius: "30px",
                              height: "35px",
                              fontSize: "15px",
                              fontFamily: "PlusJakartaSans-Regular",
                            },
                          }}
                          sx={{ marginRight: "10px" }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </>
              ))}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="20px"
            >
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "#FFE500",
                  textTransform: "none",
                  borderRadius: "30px",
                  color: "black",
                  border: "1px solid #DDDDDD",
                  fontSize: "15px",
                  fontFamily: "PlusJakartaSans-Regular",
                }}
                startIcon={<ControlPointIcon />}
                onClick={handleAddFilter}
              >
                Add Filter
              </Button>

              <p
                style={{
                  cursor: "pointer",
                  margin: 0,
                  textDecoration: "underline",
                  fontSize: "15px",
                  color: "black",
                  fontFamily: "PlusJakartaSans-Regular",
                }}
                onClick={handleCleanAll}
              >
                Clean All
              </p>
            </Box>
          </Box>
        </Box>
      </Modal>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text marginTop="20px">{listingsTableData?.data?.length} Results</Text>
        <Button
          sx={{
            backgroundColor: "white",
            borderRadius: "30px",
            textTransform: "none",
            color: "black",
            border: "1px solid #DDDDDD",
            width: "180px",
            fontFamily: "PlusJakartaSans-Regular",
          }}
          startIcon={<img src={SplitscreenIcon} />}
        >
          Choose Columns
        </Button>
      </div>
      {/* <GridItem> */}
      <Box>
        <Table
          tableData={listingsTableData}
          onRowClick={(item) =>
            handleRowClick(item, setSelectedListing, setOpenDrawer)
          }
          selectedRowId={selectedListing}
          setCheck={setCheck}
          check={check}
        />
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {/* <button
              onClick={handleLoadMore}
              style={{
                backgroundColor: "black",
                border: "none",
                color: "white",
              }}
            >
              Load More
            </button> */}

            <Button
              variant="outlined"
              style={{
                backgroundColor: "black",
                textTransform: "none",
                borderRadius: "30px",
                color: "white",
                border: "1px solid #DDDDDD",
                fontFamily: "PlusJakartaSans-Regular",
              }}
              startIcon={<KeyboardArrowDownIcon />}
              onClick={handleLoadMore}
            >
              Load More
            </Button>
          </div>
        )}
      </Box>
      {/* </GridItem> */}

      {/* <Button onClick={toggleDrawer(true)}>Open drawer</Button> */}
      <Drawer
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: "#11111180 ",
              backdropFilter: "blur(2px)",
              // zIndex: "99999",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            height: "97.5vh",
            margin: "10px",
            px: "40px",
            py: "40px",
            overflow: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "6px",
              height: "6px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
              borderRadius: "16px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4caf50",
              borderRadius: "16px",
              border: "none",
            },

            // overflowY: 'auto',

            width: "40%",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Text
              sx={{
                color: "black",
                fontFamily: "PlusJakartaSans-Regular",
                fontWeight: "600",
                fontSize: "24px",
              }}
            >
              32 Texas Avenue
            </Text>
            <img
              src={closeIcon}
              alt="Close Icon"
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(false)}
            />
          </Box>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList
                sx={{
                  "& .MuiTabs-indicator": { backgroundColor: "black" }, // Style for the underline
                  "& .MuiTab-root": {
                    color: "#000000B2",
                    fontFamily: "PlusJakartaSans-Regular",
                    textTransform: "none",
                    mr: "-14px",
                  }, // Default tab text color
                  "& .MuiTab-root.Mui-selected": {
                    color: "black",
                    fontFamily: "PlusJakartaSans-Bold",
                    textTransform: "none",
                    mr: "-14px",
                  }, // Active tab text color
                }}
                onChange={handleChangeTabs}
                aria-label="lab API tabs example"
              >
                <Tab label="Information" value="Information" />
                <Tab label="Details" value="Details" />
                <Tab label="Events & Offers" value="Events & Offers" />
                <Tab label="Documents" value="Documents" />
                <Tab label="Photos" value="Photos" />
              </TabList>
            </Box>
            <TabPanel
              value="Information"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              {" "}
              <ManageListingsUpdateForm
                listing={listing as DetailedListing}
                removeListing={async (params: RemoveListingFunctionParams) => {
                  const res = await removeListing(params);
                  api.success({
                    message: `Listing removed`,
                    placement: "bottomRight",
                  });
                  return res;
                }}
                loadingRemoveListing={loadingRemoveListing}
                errorRemoveListing={!!errorRemoveListing}
                setCheck={setCheck}
                setOpenDrawer={setOpenDrawer}
              />
            </TabPanel>

            <TabPanel
              value="Details"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Details
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
              >
                <ListTable
                  tableData={listingDetailsTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Features
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingFeaturesTableData}
                  showSplit={true}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Conditions
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingConditionsTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Flood Info
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
              >
                <ListTable
                  tableData={listingFloodInfoTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                More Info
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingMoreInfoTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Defects
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingDefectsTableData}
                  showSplit={true}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Exclusions
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingExclusionsTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Seller Info
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingSellerTableData}
                  showSplit={false}
                />
              </Box>
              {/* <Grid container spacing={2} alignItems="flex-start">
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Details
                  </Text>
                  <ListTable tableData={listingDetailsTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Features
                  </Text>
                  <ListTable tableData={listingFeaturesTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Conditions
                  </Text>
                  <ListTable tableData={listingConditionsTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Flood Info
                  </Text>
                  <ListTable tableData={listingFloodInfoTableData} />
                </GridItem>
              </Grid>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                marginTop="20px"
              >
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    More Info
                  </Text>
                  <ListTable tableData={listingMoreInfoTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Defects
                  </Text>
                  <ListTable tableData={listingDefectsTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Exclusions
                  </Text>
                  <ListTable tableData={listingExclusionsTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Seller Info
                  </Text>
                  <ListTable tableData={listingSellerTableData} />
                </GridItem>
              </Grid> */}
            </TabPanel>

            <TabPanel
              value="Events & Offers"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              {" "}
              <GridItem>
                <Text
                  sx={{
                    fontFamily: "PlusJakartaSans-Regular",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  marginBottom="8px"
                >
                  Events
                </Text>
                <Table setCheck={setCheck} tableData={listingEventsTableData} />
              </GridItem>
              <GridItem marginTop="20px">
                <Text
                  sx={{
                    fontFamily: "PlusJakartaSans-Regular",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  marginBottom="8px"
                >
                  Offers
                </Text>
                <Table setCheck={setCheck} tableData={listingOffersTableData} />
              </GridItem>
            </TabPanel>
            <TabPanel
              value="Documents"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              {" "}
              <ManageListingsInspectionDocs
                selectedListing={selectedListing}
                reloadListing={() => fetchListingDetails(selectedListing)}
              />
            </TabPanel>
            <TabPanel
              value="Photos"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              <div>
                <ManageListingsPhotoSelector
                  listing={listing}
                  reloadListing={() => fetchListingDetails(selectedListing)}
                />
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </Drawer>

      {/* <>
        {selectedListing && (
          <>
            <div style={{ marginBottom: "40px" }}>
              <ManageListingsUpdateForm
                listing={listing as DetailedListing}
                removeListing={async (params: RemoveListingFunctionParams) => {
                  const res = await removeListing(params);
                  api.success({
                    message: `Listing removed`,
                    placement: "bottomRight",
                  });
                  return res;
                }}
                loadingRemoveListing={loadingRemoveListing}
                errorRemoveListing={!!errorRemoveListing}
              />
            </div>
            <Grid container spacing={2} alignItems="flex-start">
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Details
                </Text>
                <ListTable tableData={listingDetailsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Features
                </Text>
                <ListTable tableData={listingFeaturesTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Conditions
                </Text>
                <ListTable tableData={listingConditionsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Flood Info
                </Text>
                <ListTable tableData={listingFloodInfoTableData} />
              </GridItem>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              marginTop="20px"
            >
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  More Info
                </Text>
                <ListTable tableData={listingMoreInfoTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Defects
                </Text>
                <ListTable tableData={listingDefectsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Exclusions
                </Text>
                <ListTable tableData={listingExclusionsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Seller Info
                </Text>
                <ListTable tableData={listingSellerTableData} />
              </GridItem>
            </Grid>

            <GridItem marginTop="20px">
              <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                Events
              </Text>
              <Table tableData={listingEventsTableData} />
            </GridItem>

            <GridItem marginTop="20px">
              <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                Offers
              </Text>
              <Table tableData={listingOffersTableData} />
            </GridItem>

            <div style={{ marginTop: "36px" }}>
              <ManageListingsInspectionDocs
                selectedListing={selectedListing}
                reloadListing={() => fetchListingDetails(selectedListing)}
              />
            </div>

            <div style={{ marginTop: "36px" }}>
              <ManageListingsPhotoSelector
                listing={listing}
                reloadListing={() => fetchListingDetails(selectedListing)}
              />
            </div>
          </>
        )}
      </> */}
    </ViewWrapper>
  );
};
