import React from "react"

interface OwnProps {
  children: React.ReactNode,
  padding?: number,
  margin?: number,
}

/*
  Table elements placed inside MUI Grid components have their right borders cut leading to bad visual appearance.
  Wrapping a Table component with TableWrapper fixes that.
*/
export const TableWrapper: React.FC<OwnProps> = ({ children, padding = 1, margin = 0 }) => {
  return (
    <div style={{ padding, margin, width: "100%", }}>
      {children}
    </div>
  )
}